@import '../../styles/utils/var';

.board{
    &-view{
        border-top: 2px solid #000;
        &-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px dashed #eee;
            padding: 1.5rem 3rem;
            h3{
                display: flex;
                gap: 0 1rem;
                font-weight: 400;
                color: #000;
                font-size: 2.2rem;
                padding-right: 1rem;
                em{
                    color: #2e7ac7;
                    font-size: 1.8rem;
                    white-space: nowrap;
                }
            }
            .date{
                white-space: nowrap;
            }
            @media (max-width: map-get($grid, lg)) {
                padding: 1.5rem;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: .5rem 0;
                h3{
                    font-size: 1.5rem;
                    gap: 0.5rem 0;
                    flex-direction: column;
                    em{
                        font-size: 1.2rem;
                    }
                }
                .date{
                    font-size: 1.1rem;
                }
            }
        }
        &-files{
            background-color: #fafafa;
            padding: 2rem 3rem;
            @media (max-width: map-get($grid, lg)) {
                padding: 1rem 1.5rem;
            }
            ul{
                li{
                    display: flex;
                    justify-content: space-between;   
                }
            }
            .file-name{
                color: #666666;
                display: inline-flex;
                gap:0 1rem;
                align-items: center;
                font-size: 1.6rem;
                width: calc(100% - 23rem);
                @media (max-width: map-get($grid, lg)) {
                    font-size: 1.2rem;
                    width: calc(100% - 17rem);
                }
                .files-name{
                    width: 100%;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: left;
                }
                &:hover,
                &:focus{
                    text-decoration: underline;
                }
                @media (max-width: map-get($grid, lg)) {
                    font-size: 1.2rem;

                }
            }
            .files-utils{
                display: flex;
                align-items: center;
                gap:0 1rem;
                button{
                    display: inline-flex;
                    align-items: center;
                    background-color: #ffffff;
                    border: 1px solid #bbbbbb;
                    color: #666666;
                    border-radius: .5rem;
                    padding: 1rem 1.6rem;
                    font-size: 1.3rem;
                    @media (max-width: map-get($grid, lg)) {
                        padding: .5rem 1rem;
                        font-size: 1rem;
                    }
                }
            }
        }
        &-content{
            padding: 3rem;
            @media (max-width: map-get($grid, lg)) {
                padding: 2rem;
                font-size: 1.2rem;
            }
        }
    }
}
.post-nav{
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    line-height: 1;
}
.prev-post,
.next-post{
    display: flex;
    align-items: center;
    position: relative;
    padding: 2.5rem 3rem;
    font-size: 1.6rem;
    color: #000000;
    .empty{
        width: 100%;
        padding: 0;
        text-align: center;
        border-bottom: 0;
    }
    &:not(:last-child){
        border-bottom: 1px solid #eee;
    }
    @media (max-width: map-get($grid, lg)) {
        padding: 1.3rem 1.5rem;
        font-size: 1.2rem;
    }
}
.post-label{
    display: inline-flex;
    align-items: center;
    gap:0 1rem;
}
.post-title{
    padding-left: 6.5rem;
    white-space: nowrap;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 28%);
    @media (max-width: map-get($grid, lg)) {
        padding-left: 3rem;
        width: calc(100% - 10rem);
    }
    
}
.post-date{
    position: absolute;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    color: #666666;
    @media (max-width: map-get($grid, lg)) {
        display: none;
    }
}
.board-foot-buttons{
    display: flex;
    align-items: center;
    margin-top: 4rem;
    &.right{
        justify-content: flex-end;
    }
}