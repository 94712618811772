@import '../../styles/utils/var';

.forgot-result{
    text-align: center;
    font-size: 2.1rem;
    line-height: 1.4;
    padding-bottom: 3rem;
    b{
        color: #242e42;
        font-size: 2.5rem;
    }
}
.forgot{
    button[type="submit"]{
        margin-top: 1.5rem;
    }
}