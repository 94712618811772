@import '../utils/var';

.header{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 11;
    height: 10rem;
    background-color: #fff;
    @media (max-width: map-get($grid,lg)) {
        height: auto;
    }
    &--wrap{
        height: 100%;
        position: relative;
        
        padding-bottom: 0;
        transition: all 0.5s;
        .active &{
            padding-bottom: 28rem;
            background-color: #fff;
            &::after{
                content:"";display: block;
                position: absolute;
                top: 10rem;
                height: .1rem;
                background-color: #ddd;
                left: 0;
                right: 0;
            }
        }
        @media (max-width: map-get($grid,lg)) {
            border-bottom: 1px solid #eee;
        }
    }
    &--container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        max-width: var(--grid-xl);
        height: 10rem;
        @media (max-width: map-get($grid,xl)) {
            margin-left: var(--gap);
            margin-right: var(--gap);
        }
        @media (max-width: map-get($grid,lg)) {
            position: relative;
            //border-bottom: 1px solid #eee;
            height: 6rem;
        }
    }
}

.header-util-link{
    display: flex;
    align-items: center;
    gap: 0 1rem;
    a{
        color: #fff;
        border-radius: 1.5rem;
        padding: .3rem 1.2rem;
        font-size: 1.3rem;
        display: inline-flex;
        align-items: center;
        gap:0 .5rem;
        i{
            background-size: cover;
        }
        &:hover{
            opacity: 0.7;
        }
    }
}
.header-util-login{
    background-color: #007FFF;
}
.header-util-register{
    background-color: #5695CB;
}