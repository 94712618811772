@import '../utils/var';

.breadcrumbNav{
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #435c7f;
}
.breadcrumb{
    display: flex;
    @media (max-width: map-get($grid,md)) {
        width: 100%;
    }
    > li{
        height: 5.5rem;
        position: relative;
        > a{
            height: 100%;
        }
        @media (max-width: map-get($grid,md)) {
            height: 4rem;
            &:not(.home){
                
                width: calc(50% - 2rem);
            }
        }
    }
    .home{
        a{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 6rem;
            background-color: #2e7ac7;
            @media (max-width: map-get($grid,md)) {
                width: 4rem;
            }
        }
    }
    .drop-button{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        height: 100%;
        width: 19rem;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
        @media (max-width: map-get($grid,md)) {
            width: 100%;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            font-size: 1.2rem;
        }
        &::after{
            content:"";
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            height: 2rem;
            width: .1rem;
            background-color: #314768;
            transform: translateY(-50%);
        }
        &[aria-expanded="true"]{
            i{
                transform: rotate(180deg);
            }
            +.drop-menu{opacity: 1;visibility: visible;}
        }
    }
    .drop-menu{
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #fff;
        transition: all .25s;
        visibility: hidden;
        opacity: 0;
        border-radius: 1rem;
        padding: 1rem 0;
        border: 1px solid #ddd;
        box-shadow: 0 0 1rem rgba(0,0,0,0.15);
        li{
            a{
                display: block;
                padding: 1rem 0 1rem 2.5rem;
                font-size: 1.45rem;
                color: #0a529d;
            }
        }
    }
}

.btn--search{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    border-left: 1px solid #314768;
    @media (max-width: map-get($grid,md)) {
        display: none;
    }
}

