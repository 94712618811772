@import "../utils/var";

.form-search{
    display: flex;
    justify-content: center;
    background-color: #ecf0f2;
    border-radius: 1rem;
    padding: 1.5rem 0;
}
.search-input{
    display: flex;
    gap: 0 0.8rem;
    width: 84rem;
    .form-select{
        width: 23.8%;
    }
    .form-control{
        width: 59.5%;
    }
    @media (max-width: map-get($grid, lg)) {
        width: 100%;
        gap: 0 .5rem;
        .button {
            white-space: nowrap;
        }
    }
}
