@import "../utils/var";

.table{
    border-top: 1px solid #000;
    table{
        width: 100%;
        th,
        td{
            line-height: 1;
            padding: 2rem;
            font-size: 1.6rem;
            @media (max-width: map-get($grid, lg)) {
                padding: 1.2rem;
                font-size: 1.3rem;
            }

        }
        tr{
            border-bottom: 1px solid #e1e1e1;
        }
        thead{

        }
        tbody{
            th{
                background-color: #fafafa;
            }
            th,
            td{
                text-align: left;
            }
        }
    }
}

.table-write{
    @media (max-width: map-get($grid, lg)) {
        tr{
            display: block;
        }
        th,
        td{
            display: block;
            width: 100%;
        }
        td{
            border-top: 1px solid #e1e1e1;
        }
    }
}

.guidelines-txt{
    font-size: 1.6rem;
    margin-bottom: 1rem;
    color: var(--font-hover);
    font-size: 1.4rem;
    @media (max-width: map-get($grid, lg)) {
        font-size: 1rem;
    }
}