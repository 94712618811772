@import '../../styles/utils/_var';

.main-section2{
    position: relative;
    padding: 14rem 0;
    &::after{
        content:"";
        display: block;
        background-color: #ecf0f2;
        height: 52rem;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: -1;
    }
    @media (max-width: map-get($grid, lg)) {
        padding: 10rem 0;
    }
    @media (max-width: map-get($grid, md)) {
        padding: 7rem 0;
        &::after{
            height: 30rem;
        }
    }
}

.main-section2-links{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0 3rem;
    margin-top: 9rem;
    @media (max-width: map-get($grid, lg)) {
        margin-top: 5rem;
    }
    @media (max-width: map-get($grid, md)) {
        margin-top: 3rem;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 2rem 0;
    }
    .item{
        position: relative;
        background-color: #242e42;
        background-repeat: no-repeat;
        background-position: right 4rem bottom 4rem;
        border-radius: 1rem;
        padding: 4rem;
        height: 36rem;
        @for $i from 1 through 3 {
            &:nth-child(#{$i}){
                background-image: url(#{$path}/main/ms2-icon-#{$i}.png);
            }
        }
        @media (max-width: map-get($grid, md)) {
            background-position: right 1.5rem bottom 1.5rem;
            aspect-ratio: 5 / 3;
            padding: 1.5rem;
            height: auto;
        }
    }
    .service-num{
        font-weight: 700;
        color: var(--font-hover);
        font-size: 1.3rem;
        @media (max-width: map-get($grid, md)) {
            font-size: 1rem;
        }
    }
    .service-name{
        color: #fff;
        font-size: 2.8rem;
        padding: 1.5rem 0 2.5rem;
        @media (max-width: map-get($grid, md)) {
            font-size: 2rem;
            padding: 1rem 0 2rem;
        }
        b{
            font-weight: 800;
        }
    }
    ul{
        li{
            color: #fff;
            opacity: 0.5;
            font-size: 1.5rem;
            line-height: 1.5;
            @media (max-width: map-get($grid, md)) {
                font-size: 1.2rem;
            }
        }
    }
    .link{
        position: absolute;
        left: 4rem;
        bottom: 4rem;
        color: #fff;
        opacity: .5;
        font-size: 1.3rem;
        @media (max-width: map-get($grid, md)) {
            font-size: 1rem;
            bottom: 1.5rem;
            left: 1.5rem;
        }
    }
}