@import "../utils/var";

.button-group{
    display: flex;
    align-items: center;
    gap: 0 2rem;
    &.center{
        justify-content: center;
    }
}

.button{
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    color: #ffffff;
    border-radius: 1rem;
    &--primary{
        background-color: #2e7ac7;
    }
    &--block{
        display: block;
        width: 100%;
    }
    &--sm{
        font-size: 1.6rem;
        padding: 0 1.5rem;
        border-radius: .5rem;
        height: 4.4rem;
        @media (max-width: map-get($grid , lg)) {
            font-size: 1.2rem;
            padding: 0 1.2rem;
            height: 3.5rem;
        }
    }
    &--md{
        font-size: 1.7rem;
        padding: 0 2.5rem;
        border-radius: .5rem;
        height: 5.6rem;
        @media (max-width: map-get($grid , lg)) {
            font-size: 1.3rem;
            padding: 0 1.8rem;
            height: 4rem;
        }
    }
    
    &--lg{
        font-size: 2.1rem;
        padding: 2rem 0;
    }
    @media (max-width: map-get($grid , lg)) {
        &--lg{
            font-size: 1.5rem;
            padding: 1.4rem;
        }
    }
    &--outline{
        border: 1px solid #2e7ac7;
        color: #2e7ac7;
        background-color: #fff;
        border-radius: .5rem;
        &:hover,
        &:focus,
        &.active{
            background-color: #2e7ac7;
            color: #fff;
        }
    }
    &--outline-black{
        border: 1px solid #555555;
        color: #555555;
        background-color: #fff;
        border-radius: .5rem;
        &:hover,
        &:focus,
        &.active{
            background-color: #555555;
            color: #fff;
        }
    }
    &--round{
        border-radius: 3rem;
    }
}
