@import '../utils/var';

.banners{    
    background: #242e42 url(#{$path}/banners/subtop.png) no-repeat 50% 50%;
    margin-bottom: 7rem;
    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20rem;
        position: relative;
    }
    h2{
        color: #fff;
        font-size: 3.2rem;
        line-height: 1;
    }
    @media (max-width: map-get($grid,xl)) {
        .row{
            margin-left: 0;
            margin-right: 0;
        }
    }
    @media (max-width: map-get($grid,lg)) {
        
    }
}

#contents:has(.step-navigation){
    .banners{
        margin-bottom: 0 !important;
    }
}

