$path: "../../../public/images";
@import "../../styles/utils/var";

.dialog{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: 0;
    -webkit-backface-visibility: hidden;
    overflow: hidden auto;
    &-container{
        text-align: center;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding: 0 8px;
        box-sizing: border-box;
        &::before{
            content: "";
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }
    &-body{
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin: 0 auto;
        text-align: left;
        z-index: 1045;
        width: 100%;
    }
    &-inner{
        background: #fff;
        padding: 4rem 2rem;
        text-align: left;
        max-width: 650px;
        margin: 40px auto;
        position: relative;
        border-radius: 2rem;
    }
    &-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 4rem;
        // background: url("#{$path}/bg/dots.png") repeat-x 0 100%;
    }
    &-title{
        color: #2e3039;
        font-size: 2.5rem;
    }
    &-close{
        background: url("#{$path}/icon/close@16x16.png") no-repeat 50% 50%;
        width: 1.6rem;
        height: 1.6rem;
        font-size: 0;
    }
    &-content{
        padding: 4rem 2rem 0; 
    }
    &-backdrop{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1042;
        overflow: hidden;
        position: fixed;
        background: #0b0b0b;
        opacity: 0.6;
    }
    &-buttons{
        display: flex;
        align-items: center;
        gap: 0 1rem;
        margin-top: 4rem;
    }
}


/* 모달 */
#dialogPorjectAdd{
    .dialog-inner{
        max-width: 48rem;
    }
}



/*  
    에셋 생성
*/
.asset-create-step{
    background-color: #ecf0f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 4rem;
    margin: -4rem -4rem 4rem;
    h4{
        color: #2e7ac7;
        font-size: 1.9rem;
    }
    span{
        color: #717171;
        font-size: 1.7rem;
        b{
            color: #2e7ac7;
        }
    }
}




.select-button{
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f8f8;
    border-radius: 1.2rem;
    padding: 2rem;
    input[type='radio']{
        position: absolute;
        left: -9999em;
    }
    &:has(input:checked){
        .round{
            &::after{
                border-width: 2px;
                border-color: #2e7ac7;
            }
            border-color: #2e7ac7;
            &::after{
                background-color: #2e7ac7;
            }
        }
    }
    span{
        display: inline-flex;
        align-items: center;
        gap: 0 1rem;
        color: #000;
        font-size: 1.7rem;
    }
    .round{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        vertical-align: middle;
        border: 1px solid #cbcbcb;
        background-color: #fff;
        transition: all 0.25s;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        &::after{
            content:"";
            display: inline-block;
            transition: all 0.25s;
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 50%;
            background-color: #fff;
        }
    }
    &::after{
        content:"";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #c6c6c6;
        border-radius: 1.2rem;
    }
    &[aria-expanded='true']{
        &::after{
            border-width: 2px;
            border-color: #2e7ac7;
        }
        .round{
            border-color: #2e7ac7;
            &::after{
                background-color: #2e7ac7;
            }
        }
    }
    &.mt-15{margin-top: 1.5rem;}
    &.-full{grid-column: -1 / 1; gap:0 2.5rem;
        > div{
            width: 50%;
            position: relative;
            z-index: 2;
        }
        
    }

}

.asset-create-tab{
    margin-top: 1rem;
    &[aria-hidden='true']{display: none;}
}

.og-data-list{
    border: 1px solid #c6c6c6;
    border-radius: 1.2rem;
    padding: 1.1rem;
    button{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 1.5rem 2rem;
        border-radius: 1.2rem;
        background-color: #fff;
        transition: all 0.25s;
        .name{
            color: #555555;
        }
        .bytes{
            color: #717171;
            font-size: 1.3rem;
        }
        &:hover,
        &:focus{
            background-color: #f8f8f8;
        }
    }
}

.assets-list{
    display: grid;
    grid-template-columns: repeat(2, minmax(0,2fr));
    gap:1.5rem;
}

.asset-detail-setting{
    .collapse{
        &-content{
            padding: 2rem 0;
        }
    }
}

#diglogDeployment{
    .dialog-inner{
        max-width: 48rem;
    }
}
.deployment-details{
    display: flex;
    flex-direction: column;
    gap: 2rem 0;
    dl{
        display: flex;
        flex-direction: column;
        gap: 1rem 0;
        line-height: 1;
        dt{
            position: relative;
            font-weight: 400;
            color: #1d1d1d;
            padding-left: 1.3rem;
            font-size: 1.5rem;
            &::after{
                content:"";
                position: absolute;
                left: 0;
                top: .5rem;
                width: .3rem;height: .3rem;
                background: #000;
                border-radius: 50%;
            }
        }
        dd{
            color: #555;
            font-size: 1.7rem;
        }
    }
}
.object-thumb{
    overflow: hidden;
    border-radius: 1rem;
    img{
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        aspect-ratio: 2 / 1;
    }
}

#diglogAccess{
    .dialog-inner{
        max-width: 48rem;
    }
}

#diglogParticipant{
    .dialog-inner{
        max-width: 48rem;
    }
}

.partic-list{
    border: 1px solid #717171;
    margin-top: 1.5rem;
    border-radius: 1rem;
    .user-detail{
        border-radius: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 0;
        padding: 1rem 2rem;
        background: #fff;
        &:first-child{
            border-radius: 1rem 1rem 0 0;
        }
        &:last-child{
            border-radius: 0 0 1rem 1rem;
        }
        &:first-child:last-child {
            border-radius: 1rem;
        }
        &:hover{
            background-color: #f0f0f0;
        }
        p{
            font-weight: 500;
        }
        small{
            font-size: 1.2rem;
        }
        button{
            display: inline-block;
            border-radius: 0.5rem;
            padding: 1rem 2rem;
            &:hover{
                background-color: #e3e3e3;
            }
        }
    }
}
.user-detail{
    position: relative;
    .dropdown-menu{
        right: 0;
        left: inherit;
        top: 50%;
        transform: translateX(calc(100% - 2rem));
    }
}

#DelModal{
    .dialog-inner{
        .dialog-content{
            padding: 0;
        }
        max-width: 40rem;
        p{
            text-align: center;
            font-size: 1.9rem;
            padding-bottom: 2.5rem;
            @media (max-width: map-get($grid, md)) {
                font-size: 1.5rem;
            }
        }
        button{
            width: 10rem;
            justify-content: center;
        }
    }
}
#NewModal{
    .dialog-inner{
        max-width: 56.7rem;
    }
    .dialog-content{
        padding: 0;
    }
    .form-textarea{
        margin-bottom: 1.5rem;
        textarea{
            margin-bottom: 1rem;
            height: 10rem;
        }
        .count{
            font-size: 1.7rem;
            text-align: right;
            color:#8e8e8e;
            .first{
                color:#2e7ac7;
            }
            @media (max-width: map-get($grid, md)) {
                font-size: 1.2rem;
            }
        }
    }
    .button-group{
        justify-content: flex-end;
        margin-bottom: 1.5rem;
        button{
            width: 12rem;
            justify-content: center;
        }
    }
    .invitation-list{
        background: #f8f8f8;
        border-radius: 0.8rem;
        padding: 3rem ;

        h3{
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }
        .invitation-list-inner{
            height: 15rem;
            overflow-y: auto;
            .invitation-list-item{
                display: flex;
                justify-content:space-between;
                color:#717171;
                font-size: 1.5rem;
                border-bottom: 1px dotted #c6c6c6;
                padding: 1.2rem 0;
                .name{

                }
                .email{
                    width: 50%;
                }
                .del{
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;
                    cursor: pointer;
                    &:after{
                        content:'';
                        width: 1.7rem;
                        height: 1.5rem;
                        display: block;
                        background-image: url(#{$path}/icon/ico-invitation.png);
                        cursor: pointer;
                    }
                }
                @media (max-width: map-get($grid, md)) {
                    flex-direction: column;
                    gap: 0.5rem;
                }
            }
        }
    }
}
#userOrganModal{
    .dialog-inner{
        max-width: 100rem;
    }
}