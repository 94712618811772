@import '../utils/var';

.mobile-menu-control{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    width: 25px;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 12;
    span{
        width: 100%;
        height: 2px;
        background-color: #000;
        transition: all 0.3s ease;
        &:nth-child(2){
            margin: 5px 0;
        }
    }
    .menu-open &{
        span{
            background-color: #333;
        }
        span:nth-child(1){
            transform: rotate(45deg) translate(5px, 5px);
        }
        span:nth-child(2){
            opacity: 0;
        }
        span:nth-child(3){
            transform: rotate(-45deg) translate(5px, -5px);
        }
    }
    @media (max-width: map-get($grid,lg)) {
        display: flex;
        opacity: 1;
        visibility: visible;
    }
}

.mobile__gnb{
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 0%;
    transition: all 0.5s;
    z-index: 10;
    background-color: #fff;
    @media (max-width: map-get($grid,lg)) {
        opacity: 0;
        visibility: hidden;
        display: block;
        .menu-open &{
            width: 100%;
            opacity: 1;
            visibility: visible;
        }
    }
}

.mobile-wrap{
    height: 100%;
    padding-top: 6rem;
}
.mobile-gnb-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    height: 6rem;
    background-color: #f8f9fa;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    z-index: 10;
    @media (max-width: map-get($grid,lg)) {
        padding: 0 1rem;
        height: 5rem;
    }
}

.mobile-menu-list{
    height: 100%;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-left: 1.7rem;
    padding-right: 1.7rem;
    padding-top: 1.25rem;
    li{
        text-align: center;
    }
    .trigger{
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 1.8rem;
        font-weight: 700;
        padding: 1.8rem;
        &[aria-expanded="true"]{
            +.depth{
                max-height: 500px;
                opacity: 1;
                padding: 1.25rem 3rem;
            }
        }
    }
    .depth{
        background-color: #f6f6f6;
        border-radius: 1.5rem;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0 3rem;
        transition: max-height .5s ease-out, padding .3s ease-out, opacity .4s ease-in;
        will-change: auto;
        ul{
            li{
                a{
                    display: block;
                    text-align: left;
                    font-size: 1.4rem;
                    padding: 1.25rem 0;
                }
            }
        }
    }
}