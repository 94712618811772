@import "../../styles/utils/var";

.terms {
  &-easy {
    background-color: #eceef2;
    border-radius: 1.5rem;
    padding: 3.2rem;
    margin-bottom: 4rem;
    p {
      color: #555555;
      line-height: 1.4;
      font-size: 1.7rem;
      padding-left: 4rem;
      padding-top: 2rem;
    }
    @media (max-width: map-get($grid, lg)) {
      padding: 2rem;
      margin-bottom: 2.5rem;
      p {
        font-size: 1.3rem;
        padding-left: 2rem;
      }
    }
  }
  &-allcheck {
    display: flex;
    align-items: center;
    gap: 0 1.5rem;
    input {
      width: 2.4rem;
      height: 2.4rem;
      border: 1px solid #c6c6c6;
      vertical-align: top;
    }
    label {
      vertical-align: top;
      font-weight: 700;
      color: #000;
      font-size: 2.1rem;
    }
    @media (max-width: map-get($grid, lg)) {
      gap: 0 0.7rem;
      input {
        width: 1.4rem;
        height: 1.4rem;
      }
      label {
        font-size: 1.3rem;
      }
    }
  }
}

.terms-consent {
  border: 1px solid #bdbdbd;
  border-radius: 1rem;
  padding: 3rem;
  .rows {
    + .rows {
      border-top: 1px solid #bdbdbd;
      padding-top: 2.5rem;
      margin-top: 2.5rem;
    }
  }
  @media (max-width: map-get($grid, lg)) {
    padding: 2rem;
    .rows {
      + .rows {
        margin-top: 2rem;
        padding-top: 2rem;
      }
    }
  }
}

.consent-title {
  color: #000000;
  font-size: 2.1rem;
  .color-red {
    color: #c72e2e;
  }
  @media (max-width: map-get($grid, lg)) {
    font-size: 1.5rem;
  }
}
.views-detail {
  margin: 2.3rem 0 2rem;
  @media (max-width: map-get($grid, lg)) {
    margin: 1.5rem 0;
  }
}

.consent-actions {
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  border-radius: 1rem;
  padding: 2rem 3rem;
  @media (max-width: map-get($grid, lg)) {
    padding: 1.5rem;
  }
  @media (max-width: map-get($grid, lg)) {
    flex-direction: column;
    gap: 1rem 0;
  }
}

.consent-desc {
  color: #2d2d2d;
  font-size: 1.7rem;
  @media (max-width: map-get($grid, lg)) {
    font-size: 1.2rem;
  }
}

.consent-check {
  display: flex;
  gap: 0 4rem;
  @media (max-width: map-get($grid, lg)) {
    gap: 0 2rem;
  }
}

.consent-con {
  height: 20rem;
  overflow-y: auto;
  padding: 1.5rem 0;
  margin-bottom: 1rem;
  h3 {
    margin-bottom: 1.5rem;
  }
  ul {
    margin-bottom: 1.5rem;
    li {
      padding-left: 0.5rem;
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
    }
  }
  ol {
    padding-top: 1rem;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
    // li{

    // }
  }

  p {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }
  table {
    th,
    td {
      font-size: 1.3rem !important;
    }
  }
}

.cont-agg-all_boxtext {
  background: #edf1f4;
  font-size: 16px;
  line-height: 1.5em;
  border-radius: 4px;
  padding: 25px;
  @media (max-width: map-get($grid, lg)) {
    font-size: 14px;
    padding-bottom: 15px;
  }
}
