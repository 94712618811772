@import '../utils/var';


.feedback{
    font-size: 1.7rem;
    margin-top: 1rem;
    &-error{
        color: #ff0000;
    }
    &-success{
        color: #2e7ac7;
    }
    @media (max-width: map-get($grid,lg)) {
        font-size: 1.2rem;
        margin-top: .5rem;
    }
}