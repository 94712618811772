@import '../../styles/utils/var';

.board-table{
    border-top: 1px solid #333;
    line-height: 1;
    
    .thead{
        display: flex;
        background-color: #fafafa;
        padding: 2rem 0;
        font-size: 1.6rem;
        font-weight: 700;
        border-bottom: 1px solid #ddd;
        text-align: center;
        @media (max-width: map-get($grid , lg)) {
            font-size: 1.3rem;
        }
        @media (max-width: map-get($grid , md)) {
            display: none;
        }
    }
    .tbody{
        display: flex;
        flex-wrap: wrap;
        padding: 1.5rem 0;
        font-size: 1.6rem;
        border-bottom: 1px solid #ddd;
        text-align: center;
        align-items: center;
        .subject{
            text-align: left;
        }
        @media (max-width: map-get($grid , lg)) {
            font-size: 1.2rem;
        }
    }
    .num{
        width: 9.5%;
    }
    .type{
        width: 15.5%;
    }
    &:has(.writer){
        .subject{
            width: 35%;
        }
    }
    .subject{
        width: 55%;
        display: inline-flex;
        align-items: center;
        a{
            display: inline-block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &:hover,
            &:focus{
                text-decoration: underline;
                color: var(--font-hover);
            }
            .icon--lock{
                vertical-align: bottom;
            }
        }
    }
    .state,
    .writer,
    .date,
    .files,
    .hit{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 10%;
    }
    @media (max-width: map-get($grid , md)) {
        .md-hidden{
            display: none;
        }
        .type{
            width: 30%;
        }
        .subject{
            width: 70%;
        }
        &:has(.writer){
            .subject{
                width: 100%;
                margin-bottom: 1rem;
            }
            .writer,
            .date{
                width: 50%;
                justify-content: flex-start;
            }
            .date{justify-content: end;}
        }
        .state,
        .date{
            width: 30%;
        }
    }
}
.badge{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    line-height: 1;
    padding: 1rem 3.5rem;
    @media (max-width: map-get($grid , md)) {
        padding: 0.4rem 1.2rem;
        font-size: 1.1rem;
    }
}
.badge-primary{
    color: #2e7ac7;
    border: 1px solid #2e7ac7;
    border-radius: 2rem;
}


.empty{
    text-align: center;
    padding: 3rem 0;
    font-size: 1.4rem;
    font-weight: 400;
    border-bottom: 1px solid #eee;
    color: #000;
}