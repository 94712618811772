@import '../../styles/utils/var';

.user-use{
    background: linear-gradient(to right, #2e78c4, #253955);
    .col-header{
        margin-bottom: 0;
        h4{
            color: #fff;
            text-decoration: underline;
            em{font-weight: 400;font-style: normal;}
        }
        
    }
}
.user-use-date{
    color: #cdd7e4;
    font-size: 1.3rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.user-use-progress{
    .desc{
        color: #cdd7e4;
        margin-bottom: .5rem;
        font-size: 1.4rem;
        b{color: #fff;}
    }
    progress[value]{
        display: block;
        width: 100%;
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance: none;
        border: 0;
        background: #fff;
        border-radius: 1rem;
        height: .7rem;
        &::-webkit-progress-bar{
            border-radius: 1rem;
            background: #cdd7e4;
        }
        &::-webkit-progress-value {
            border-radius: 1rem;
            background: #f16621;
        }
    }
}

.user-use-upgrade{
    display: block;
    width: 100%;
    background-color: #ffffff;
    font-weight: 700;
    color: #2e7ac7;
    font-size: 1.7rem;
    border: 1px solid #2e7ac7;
    border-radius: 3rem;
    height: 4.8rem;
    margin-top: 4rem;
}

// user-project
.user-project{
    background-color: #edf1f5;
    
}
.user-project-list{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 0 3rem;
    &.type-02{
        grid-template-columns: repeat(2,1fr);
    }
    @media (max-width: map-get($grid, lg)) {
        gap: 0 1rem;
    }
}

.project-item{
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
    justify-content: center;
    align-items: center;
    background-color: #3078c4;
    border-radius: 1rem;
    height: 13.8rem;
    box-shadow: 0 0 .5rem rgba(0,0,0,0.55);
    div{color: #fff;}
    &-title{font-size: 1.7rem;}
    &-count{font-size: 4.8rem;font-weight: 700;}
}

// 작업이력
.user-worked-list{
    li{
        padding: 1.3rem 0;
        border-top: 1px solid #ddd;
        &:last-child{border-bottom: 1px solid #edf1f4}
    }
}
.worked-item-title{
    color: #000;
    font-size: 1.5rem;
}
.worked-item-date{
    color: #8e8e8e;
    font-size: 1.2rem;
}

// 공유 폴더
.share-link-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 1rem;
}
.share-link{
    &:nth-child(2){
        border-left: 1px solid #edf1f4;
        border-right: 1px solid #edf1f4;
    }
    > a{
        display: flex;
        flex-direction: column;
        gap : 1rem 0;
        align-items: center;
        width: 100%;
        span{
            text-align: center;
            color: #000;
            font-size: 1.5rem;
        }
    }
}

.col-row2-item{
    align-items: stretch;
    .col-row2-item01{
        &>.col{
            height: 100%;
            .progress-ui{
                display: block;
                .progress-group{
                    width: 100%;
                    margin-top: 0.5rem;
                }
            }
        }
    }
    .col-row2-item02{
        &>div{
            &+div{
                margin-top: 3rem;
            } 
        }
    }

}