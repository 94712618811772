@import '../../styles/utils/var';

.faq-item{
    border: 1px solid #ddd;
    border-radius: .5rem;
    overflow: clip;
    +.faq-item{
        margin-top: 2rem;
    }
}
.faq-heading{
    &:has([aria-expanded='false']){
        background-color: #fafafa;
        border-bottom: 1px solid #ddd;
    }
    > button{
        display: flex;
        position: relative;
        align-items: center;
        color: #000;
        width: 100%;
        padding: 1.5rem 0 1.5rem 8.5rem;
        font-size: 1.8rem;
        @media (max-width: map-get($grid , lg)) {
            padding: 1.2rem 0 1.2rem 5.5rem;
            font-size: 1.4rem;
        }
        i{
            display: block;
            position: absolute;
            font-style: normal;
            left: 3.5rem;
            top: 50%;
            transform: translateY(-50%);
            color: #091634;
            font-weight: 900;
            font-size: 2.4rem;
            @media (max-width: map-get($grid , lg)) {
                left: 1.5rem;
                font-size: 1.5rem;
            }
        }
        &::after,
        &::before{
            content:"";
            display: block;
            position: absolute;
            right: 3.5rem;
            top: 50%;
            transition: all 0.25s;
        }
        &::before{
            width: 1.8rem;
            height: .2rem;
            background-color: #a3a7b3;
            transform: translateX(.8rem) rotate(0deg);
            @media (max-width: map-get($grid , lg)) {
                width: 1.2rem;
                transform: translateX(.5rem) rotate(0deg);
            }
        }
        &::after{
            width: .2rem;
            height: 1.8rem;
            background-color: #a3a7b3;
            transform: translateY(-.8rem) rotate(0deg);
            opacity: 1;
            @media (max-width: map-get($grid , lg)) {
                height: 1.2rem;
                transform: translateY(-.5rem) rotate(0deg);
            }
        }
        &[aria-expanded='false']{
            &::after{
                opacity: 0;
            }
            &::before{transform: translateX(.8rem) rotate(180deg);background-color: #182440;
                @media (max-width: map-get($grid , lg)) {
                    transform: translateX(.5rem) rotate(180deg)
                }
            }
            &::after{transform: translateY(-.8rem) rotate(180deg);
                @media (max-width: map-get($grid , lg)) {
                    transform: translateY(-.5rem) rotate(180deg);
                }
            }
        }
    }
}
.faq-title{
    color: #000;
    font-size: 1.8rem;
    padding-left: 3.5rem;
    @media (max-width: map-get($grid , lg)) {
        font-size: 1.3rem;
        padding-left: 2rem;
    }
}
.faq-category{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #2e7ac7;
    border-radius: 2.5rem;
    color: #2e7ac7;
    font-size: 1.6rem;
    width: 11.5rem;
    height: 3.8rem;
    [aria-expanded='false'] &{
        background-color: #2e7ac7;
        color: #fff;
    }
    @media (max-width: map-get($grid , lg)) {
        font-size: 1.2rem;
        width: 8rem;
        height: 3rem;
    }
}
.faq-answer{
    padding: 3.5rem;
    line-height: 1.5;
    color: #666666;
    font-size: 1.6rem;
    display: none;
    @media (max-width: map-get($grid , lg)) {
        font-size: 1.3rem;
        padding: 2rem;
    }
    &.open{
        display: block;
    }
}