@import '../../styles/utils/_var';

.main-section5{
    padding: 11rem 0 10rem;
    @media (max-width: map-get($grid, lg)) {
        padding: 8rem 0 7rem;
    }
    @media (max-width: map-get($grid, md)) {
        padding: 6rem 0 5rem;
    }
    .wraps{
        display: flex;
        justify-content: space-between;
        padding-top: 10rem;
        gap: 0 10rem;
        @media (max-width: map-get($grid, lg)) {
            padding-top: 7rem;
            gap: 0 5rem;
        }
        @media (max-width: map-get($grid, md)) {
            padding-top: 3cqb;
            flex-direction: column;
            gap: 0;
        }
    }
}
.latest{
    width: calc(100% - 32%);
    @media (max-width: map-get($grid, md)) {
        width: 100%;
    }
    .tablist{
        display: grid;
        grid-template-columns: repeat(2, minmax(0,1fr));
        &-item{
            color: #000;
            background-color: #edf1f4;
            transition: all 0.25s;
            font-weight: 700;
            height: 6rem;
            font-size: 1.8rem;
            border-radius: .5rem .5rem 0 0;
            &[aria-selected='true']{
                color: #fff;
                background-color: #242e42;
            }
            @media (max-width: map-get($grid, lg)) {
                font-size: 1.4rem;
                height: 4.5rem;
            }
            @media (max-width: map-get($grid, md)) {
                font-size: 1.3rem;
                height: 4.2rem;
            }
        }
    }
    &-content{
        ul{
            li{
                line-height: 1;
                padding: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #ddd;
                @media (max-width: map-get($grid, md)) {
                    padding: 1.4rem;
                }
                a{
                    display: inline-block;
                    width: calc(100% - 16rem);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: 700;
                    font-size: 2.2rem;
                    @media (max-width: map-get($grid, lg)) {
                        font-size: 1.4rem;
                        width: calc(100% - 11rem);
                    }
                }
                .date{
                    display: flex;
                    align-items: cente;
                    gap:0 3rem;
                    @media (max-width: map-get($grid, lg)) {
                        gap: 0 1rem;
                    }
                    span{color: #555;white-space: nowrap;}
                    span:nth-child(1){font-size: 1.5rem;
                        @media (max-width: map-get($grid, lg)) {
                            font-size: 1rem;
                        }
                    }
                    span:nth-child(2){font-size: 1.6rem;
                        @media (max-width: map-get($grid, lg)) {
                            font-size: 1.1rem;
                        }
                    }
                    
                }
            }
        }
    }
}

.banner-slide{
    overflow: hidden;
    position: relative;
    width: calc(32%);
    @media (max-width: map-get($grid, md)) {
        margin-top: 4rem;
        width: 100%;
    }
    img{
        width: 100%;
    }
    .swiper-pagination{
        bottom: inherit !important;
        top: 3.5rem !important;
        right: 3.5rem !important;
        width: auto !important;
        text-align: right;
    }
    .swiper-pagination-bullet{background-color: #bbbdd1;opacity: 1;height: 1.2rem;width: 1.2rem;
        &.swiper-pagination-bullet-active{
            background-color: #fff;
            width: 3rem;
            border-radius: 1.5rem;
        }
    }
}