@import '../../styles/utils/var';

.progress-ui{
    display: flex;
    align-items: center;
    justify-content: space-between;
    +.progress-ui{
        margin-top: .8rem;
    }
    label{
        line-height: 1;
        display: inline-block;
        position: relative;
        padding-left: 1.6rem;
        font-size: 1.5rem;
        &::after{
            content:"";
            display: inline-block;
            position: absolute;
            width: .6rem;height: .6rem;
            border-radius: 50%;
            left: 0;
            top: .3rem;
        }
    }
    .progress-group{
        width: calc(100% - 16rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #edf1f4;
        border-radius: .5rem;
        padding: .4rem .8rem;
        progress{
            display: block;
            //width: calc(100% - 9.5rem);
        }
        progress[value]{
            display: block;
            width: calc(100% - 9.5rem);
            -webkit-appearance:none;
            -moz-appearance:none;
            appearance: none;
            border: 0;
            background: #fff;
            border-radius: 1rem;
            height: .7rem;
            &::-webkit-progress-bar{
                border-radius: 1rem;
                background: #fff;
            }
        }
        

        .count{
            text-align: right;
            color: #000;
            font-size: 1.4rem;
        }
    }
    &.theme-blue{
        label{
            &::after{
                background-color: #4a639b;
            }
        }
        progress[value]{
            &::-webkit-progress-value {
                border-radius: 1rem;
                background: #4a639b;
            }
        }
    }
    &.theme-sky{
        label{
            &::after{
                background-color: #6dc9eb;
            }
        }
        progress[value]{
            &::-webkit-progress-value {
                border-radius: 1rem;
                background: #6dc9eb;
            }
        }
    }
    &.theme-darksky{
        label{
            &::after{
                background-color: #5db2e0;
            }
        }
        progress[value]{
            &::-webkit-progress-value {
                border-radius: 1rem;
                background: #5db2e0;
            }
        }
    }
}