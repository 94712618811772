@import '../../styles/utils/var';


.regi-complete{   
    @media (max-width: map-get($grid,lg)){
        
    }
}

.welcome-message{
    text-align: center;
    p{
        line-height: 1.7;
        font-size: 2.1rem;
        margin-top: 3rem;
        b{
            color: var(--font-hover);
        }
    }
    @media (max-width: map-get($grid,lg)){
        p{
            font-size: 1.5rem;
            margin-top: 2rem;
        }
    }
    @media (max-width: map-get($grid,md)){
        p{
            font-size: 1.3rem;
        }
    }
}