@import '../../styles/utils/var';

.user-tbl{
    
    li{
        display: table !important;
        width: 100%;
        @media (max-width: map-get($grid, md)) {
            &.thead{
                display: none !important;
            }
            &.tbody{
                display: flex !important;
                span{
                    width: 25% !important;
                    text-align: center;
                    padding: 1rem;
                }
            }
        }
        span{
            display: table-cell;

        }
        span{
            width: 10%;
            &.small{
                width: 5%;
            }
        }
    }
    .ico-user-btn{
        vertical-align: bottom;
        cursor:pointer
    }
}

.user-controls{
    @media (max-width: map-get($grid, md)) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
    
    .board-sort{
        &>div{
            display: flex;
            gap: 0.5rem;
        }
        @media (max-width: map-get($grid, md)) {
            width: auto;
        }
    }
}