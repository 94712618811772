@import '../../styles/utils/var';

#contents:has(.step-navigation){
    .button-group{
        margin-top: 3rem;
    }
}

.step-navigation{
    background: #f8f8f8;
    padding: 8rem 0;
    @media (max-width: map-get($grid,lg)) {
        padding: 5rem 0;
    }
}

.step-wraps{
    position: relative;
    display: flex;
    flex-wrap:wrap;

    &::after{
        content:"";
        display: block;
        position: absolute;
        left: 0;
        top: 1.5rem;
        width: 100%;
        background-color: #c6c6c6;
        height: .2rem;
        @media (max-width: map-get($grid,lg)) {
            top: 1rem;
            height: .1rem;
        }
    }
}

.step-navigation-item{
    position: relative;
    z-index: 2;
    width: 50%;
    .deco{
        position: relative;
        border-radius: 50%;
        background-color: #e4e4e4;
        width: 3.2rem;
        height: 3.2rem;
        &::after{
            content:"";
            display: block;
            position: absolute;
            top: .3rem;
            right: .3rem;
            bottom: .3rem;
            left: .3rem;
            border: 2px solid #fff;
            border-radius: 50%;
        }
        &.active{
            background-color: #2e7ac7;
        }
        @media (max-width: map-get($grid,lg)) {
            width: 2rem;
            height: 2rem;
        }
    }
    p{
        line-height: 1;
    }
    &:last-child{
        display: flex;
        flex-direction: column;
        align-items: end;
        position: absolute;
        right: 0;
    }
}
.step-show{
    color: #717171;
    font-size: 1.7rem;
    padding: 2rem 0 1.5rem;
    @media (max-width: map-get($grid,lg)) {
        font-size: 1.1rem;
        padding: 1rem 0 .7rem;
    }
}
.step-show+p{
    color: #000;
    font-size: 1.9rem;
    @media (max-width: map-get($grid,lg)) {
        font-size: 1.2rem;
    }
    @media (max-width: map-get($grid,md)) {
        display: none;
    }
}