@import '../../styles/utils/var';


$columns: 12;
$gap: 3rem; // gap 값을 설정합니다.

@for $i from 1 through $columns {
  .col-#{$i} {
    @if $i == $columns {
      width: 100%;
    } @else {
      //width: calc(percentage($i / $columns) - #{$gap * (($columns - 1) / $columns)});
      width: calc(percentage($i / $columns));
    }
  }
}

@media (max-width : map-get($grid , lg)) {
  @for $i from 1 through $columns {
    .col-lg-#{$i} {
      @if $i == $columns {
        width: 100%;
      } @else {
        //width: calc(percentage($i / $columns) - #{$gap * (($columns - 1) / $columns)});
        width: calc(percentage($i / $columns));
      }
    }
  }  
}
@media (max-width : map-get($grid , md)) {
  @for $i from 1 through $columns {
    .col-md-#{$i} {
      @if $i == $columns {
        width: 100%;
      } @else {
        //width: calc(percentage($i / $columns) - #{$gap * (($columns - 1) / $columns)});
        width: calc(percentage($i / $columns));
      }
    }
  }  
}

.col-3-1 {
  width:  calc(25% + 18.5px);
}

.grid {
  display: flex;
  //flex-wrap: wrap;
  gap: $gap;
  +.grid{
    margin-top: $gap;
  }
  @media (max-width: map-get($grid , md)) {
    flex-wrap: wrap;  
  }
}
.col{
  border: 1px solid #c6c6c6;
  border-radius: 1rem;
  padding: 3rem;
  background-color: #fff;
  @media (max-width: map-get($grid , lg)) {
    padding: 1.5rem;
  }
}

.col-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  @media (max-width: map-get($grid , lg)) {
    margin-bottom: 1rem;
  }
  h4{
      color: #000;
      line-height: 1;
      font-size: 1.9rem;
      //margin-bottom: 1.5rem;
      @media (max-width: map-get($grid , lg)) {
        font-size: 1.4rem;
        
      }
  }
}