@import '../utils/var';



[class^="icon--"]{
    display: inline-block;
    vertical-align: top;
    line-height: 1;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.icon--home{
    background-image: url(#{$path}/icon/home-white@16x18.png);
    width: 1.6rem;
    height: 1.8rem;
}
.icon--search{
    width: 1.8rem;
    height: 1.8rem;
    background-image: url(#{$path}/icon/search-white@18x18.png);
}

.icon--arw-white{
    width: 1.2rem;
    height: .7rem;
    background-image: url(#{$path}/icon/arrow@12x7-white.png);
}


.icon--arw-black{
    width: 1.2rem;
    height: .7rem;
    background-image: url(#{$path}/icon/arrow@12x6-black.png);
}

.icon--arwup-black{
    width: 1.2rem;
    height: .7rem;
    background-image: url(#{$path}/icon/arrow@12x6-black.png);
    transform: rotate(180deg);
}

.icon--arwright-black{
    width: .6rem;
    height: 1.1rem;
    background-image: url(#{$path}/icon/arrowright@6x11-black.png);
}


.icon--arwleft-black{
    width: .6rem;
    height: 1.1rem;
    background-image: url(#{$path}/icon/arrowright@6x11-black.png);
    transform: rotate(180deg);
}


.icon--arw-gray{
    width: 2.1rem;
    height: 1.5rem;
    background-image: url(#{$path}/icon/arrow@21x15-gray.png);
}

.icon--check{
    width: 1.8rem;
    height: 1.8rem;
    background-image: url(#{$path}/icon/check@18x18.png);
}

.icon--user-big{
    width: 12rem;
    height: 12rem;
    background-image: url(#{$path}/icon/user@120x120.png);
}

.icon--setting-white{
    width: 1.6rem;
    height: 1.6rem;
    background-image: url(#{$path}/icon/setting@16x16.png);
}
.icon--more{
    width: 2.4rem;
    height: 2.4rem;
    background-image: url(#{$path}/icon/more@24x24.png);
}

.icon--folder{
    width: 3.7rem;
    height: 3.1rem;
    background-image: url(#{$path}/icon/folder@37x31.png);
}

.icon--star{
    width: 3rem;
    height: 2.9rem;
    background-image: url(#{$path}/icon/start@30x29.png);
    [aria-pressed="true"] &{
        background-image: url(#{$path}/icon/start-active@30x29.png);
    }
}

.icon--arrowdown-white{
    width: 3.8rem;
    height:1.8rem;
    background-image: url(#{$path}/icon/arrowdown@34x18-white.png);
}



.icon--login{
    width: 2.4rem;
    height:2.4rem;
    background-image: url(#{$path}/icon/login@24x24-white.png);
}
.icon--register{
    width: 2.4rem;
    height:2.4rem;
    background-image: url(#{$path}/icon/register@24x24-white.png);
}

$files : xlsx,hwp,pdf,img,pptx,txt;
@each $type in $files {
    .icon--#{$type} {
      width: 2.3rem;
      height: 2.5rem;
      background-image: url(#{$path}/icon/file-#{$type}@23x25.png);
    }    
}


.icon--download {
    width: 1.8rem;height: 1.8rem;
    background-image: url(#{$path}/icon/download@18x18.png);
}    
.icon--preview{
    width: 1.8rem;height: 1.8rem;
    background-image: url(#{$path}/icon/preview@18x18.png);
}

.icon--lock{
    width: 1.8rem;height: 1.8rem;
    background-image: url(#{$path}/icon/lock@18x18.png);
}

.icon--quick{
    width: 2.4rem;height: 2.4rem;
    background-image: url(#{$path}/icon/link-header@24x24.png);
}