@import '../../styles/utils/var';


.mypage-title{
    border-bottom: .2rem solid #000;
    padding-bottom: 1.5rem;
    margin-bottom: 6rem;
    h3{
        line-height: 1;
        color: #000;
        font-size: 4rem;
    }
    @media (max-width: map-get($grid, lg)) {
        border-width: .1rem;
        margin-bottom: 3.5rem;
        h3{
            font-size: 2.5rem;
        }
    }
}