@import "../utils/var";


.category-list{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    border: 1px solid #c6c6c6;
    margin-bottom: 3rem;
    @media (max-width: map-get($grid, lg)) {
        margin-bottom: 2rem;
    }
}
.button-trigger{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2rem 0;
    font-size: 1.9rem;
    color: #555555;
    @media (max-width: map-get($grid, lg)) {
        padding: 1.2rem 0;
        font-size: 1.4rem;
    }
    @media (max-width: map-get($grid, md)) {
        font-size: 1rem;
    }
    span{
        display: block;
        position: relative;
        z-index: 2;
    }
    &::after{
        content:"";
        display: block;
        position: absolute;
        left: 0;
        top: -1px;
        right: 0;
        bottom: -1px;
        border: 1px solid #2e7ac7;
        border-bottom-width: 3px;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        @media (max-width: map-get($grid, md)) {
            border-bottom-width: 2px;
        }
    }
    &:not(:first-child){
        border-left: 1px solid #c6c6c6;
    }
    &[aria-pressed='true']{
        color: #2e7ac7;
        font-weight: 700;
        &::after{
            opacity: 1;
            visibility: visible;
        }
    }
}

