@import '../utils/var';

%headerUtilsButton{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
    width: 3.6rem;
    height: 3.6rem;
    background-color: #e0e0e0;
    border: 1px solid #d9d9d9;
    i{
        display: inline-block;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
    &[aria-expanded="true"]{
        +.-toolbox{
            opacity: 1;
            visibility: visible;
        }
    }
}
.header__util{
    display: flex;
    align-items: center;
    gap: 0 1.6rem;
    @media (max-width: map-get($grid,lg)) {
        display: none;
    }
}
.header--alert{
    position: relative;
    
    > button{
        @extend %headerUtilsButton;
        i{
            background-image: url(#{$path}/icon/alram-header@20x20.png);
            width: 2rem;height: 2rem;
        }
        .alert--count{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(50%,-50%);
            width: 2.3rem;
            height: 2.3rem;
            border-radius: 50%;
            background-color: #eb6100;
            color: #fff;
            line-height: 1;
            font-size: 1.4rem;
        }
        &[aria-expanded="true"]{
            +.-toolbox{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &--detail{
        z-index: 10;
        position: absolute;
        right: 0;
        transform: translate(8.5rem,1.5rem);
        top: 100%;
        border: 1px solid #d8d8d8;
        background-color: #fff;
        border-radius: 1rem;
        padding: .8rem;
        display: flex;
        gap:0 .8rem;
        opacity: 0;
        visibility: hidden;
    }
    &--item{
        background-color: #ecf0f2;
        border-radius: 1.5rem;
        height: 12rem;
        width: 13rem;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .alert-label{font-size: 1.7rem;text-align: center;}
        .alert-count{
            text-align: center;
            font-size: 1.7rem;
            padding-top: 1.7rem;
            line-height: 1;
            strong{
                color: #2e7ac7;
                font-size: 3.6rem;
            }
        }
    }
}

.header--profile{
    position: relative;
    > button{
        @extend %headerUtilsButton;
        align-items: end;
        i{
            background-image: url(#{$path}/icon/user-header@19x28.png);
            width: 2rem;height: 2.8rem;
        }
    }
    &--detail{
        z-index: 10;
        border: 1px solid #d8d8d8;
        background-color: #fff;
        border-radius: 1rem;
        position: absolute;
        right: 0;
        top: 100%;
        transform: translate(8.5rem,1.5rem);
        opacity: 0;
        visibility: hidden;
        .user-profile{
            display: flex;
            align-items: center;
            padding: 2.5rem 3rem 3rem;
            gap: 0 2rem;
            width: 26rem;
        }
        .user--avatar{
            border: 1px solid #d8d8d8;
            background-color: #e4e4e4;
            border-radius: 50%;
            position: relative;
            height: 6rem;
            width: 6rem;
            background-image: url(#{$path}/icon/user-header@41x55.png);
            background-repeat: no-repeat;
            background-position: center bottom;
            img{
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .user--info{
            line-height: 1;
            .name{color: #666666;font-size: 1.6rem;}
            .company{color: #222;font-size: 2.4rem;margin-top: .5rem;}
        }
        .buttons{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            border-top: 1px solid #e0e0e0;
            div{
                text-align: center;
                padding: 1.4rem 0;
                line-height: 1;
                &:first-child{
                    position: relative;
                    &::after{
                        content:"";
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        height: 1.4rem;
                        width: .1rem;
                        margin-top: -.7rem;
                        background-color: #eeeeee;
                    }
                }
                a{
                    color: #666666;
                    font-size: 1.4rem;
                }
            }
            .em{color: #2e7ac7;}
        }
    }
}

.header-util-quick{
    z-index: 10;
    position: relative;
    &-inner{
        border: 1px solid #d8d8d8;
        background-color: #fff;
        border-radius: 1rem;
        position: absolute;
        right: 82px;
        top: 100%;
        transform: translate(8.5rem,1.5rem);
        opacity: 0;
        visibility: hidden;
        color:#fff;
        display: flex;
        align-items: center;
        padding: 0.8rem;
        gap: 0.8rem;
        width: 42rem;
        &>a{
            display: block;
            height: 13rem;
            flex: 1;
            background: #ecf0f2;
            border-radius: 1.2rem;
            font-size: 1.7rem;
            text-align: center;
            
            &.vider-servie{
                i{
                    background-image: url(#{$path}/icon/link-header-ico@29x24.png);
                }
            }
            &.AIdata-srervice{
                i{
                    background-image: url(#{$path}/icon/link-header-ico@27x30.png);
                }
            }
            &.dtMap-service{
                i{
                    background-image: url(#{$path}/icon/link-header-ico01@30x30.png);
                }
            }
            i{
                width: 100%;
                height: 6.5rem;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                justify-content: center;
                background-position: center;
            }
            span{
                display: inline-block;
            }
        }
    }
    >button{
        &[aria-expanded="true"]{
            +.-toolbox{
                opacity: 1;
                visibility: visible;
            }
        }
    }
}