@import "../utils/var";

.form-group{
    .labeling,
    .label{
        line-height: 1;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 1.5rem;
        font-size: 1.7rem;
    }
    +.form-group{
        margin-top: 3rem;
    }
    @media (max-width: map-get($grid,lg)){
        .labeling,
        .label{
            font-size: 1.3rem;
            margin-bottom: 0.7rem;
        }
        +.form-group{
            margin-top: 1.4rem;
        }
    }

    .label-desc{
        color: #555;
        font-size: 1.5rem;
        @media (max-width: map-get($grid,lg)){
            font-size: 1.2rem;
        }
    }

    &:has(.label-desc){
        .label,
        .labeling{
            margin-bottom: .5rem;
        }
        .label-desc{
            margin-bottom: 1.5rem;
            @media (max-width: map-get($grid,lg)){
                margin-bottom: .7rem;
            }
        }
    }
}
.form-radio-group{
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
    &._row{
        flex-direction: row;
        gap: 0 2rem;
    }
}



.form-control,
.form-select{
    display: inline-block;
    vertical-align: top;
    //width: 100%;
    border: 1px solid #c6c6c6;
    border-radius: .5rem;
    background-color: #fff;
    font-size: 1.6rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 4.4rem;
    color: #666666;
    &.block{
        display: block;
        width: 100%;
    }
    @media (max-width: map-get($grid,lg)){
        height: 3.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1.2rem;
    }
}

textarea.form-control{
    overflow-y: auto;
    resize: none;
    height: 15rem;
    padding-top: 1rem;
}
.form-control{
    &::placeholder{
        color: #8e8e8e;
    }
}


.at,
.hipen{
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    @media (max-width: map-get($grid,lg)){
        font-size: 1.2rem;
    }
}
/* email form */
.form-email{
    display: flex;
    align-items: center;
    gap:0 1rem;
    @media (max-width: map-get($grid, md)) {
        gap:0;
        .form-control{
            width: 56%;
        }
        .at{
            width: 7%;
            justify-content: center;
        }
        .form-select{
            width: 44%;
        }
    }
}
.form-tel{
    display: flex;
    align-items: center;
    gap:0 1rem;
}

.label-radio,
.label-checkbox{
    display: inline-flex;
    align-items: center;
    gap: 0 1rem;
    color: #2d2d2d;
    font-size: 1.7rem;
    @media (max-width: map-get($grid,lg)){
        font-size: 1.2rem;
    }
    input{
        position: absolute;
        left: -9999em;
        &:checked+i{
            border-color: #337dc8;
            &::after{
                background-color: #337dc8;
            }
        }
    }
    i{
        display: inline-block;
        vertical-align: middle;
        position: relative;
        border-radius: 50%;
        width: 2rem;height: 2rem;
        background-color: #fff;
        border: 1px solid #c7c7c7;
        @media (max-width: map-get($grid,lg)){
            width: 1.4rem;
            height: 1.4rem;
        }
        &::after{
            content:"";
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 1rem;height: 1rem;
            border-radius: 50%;
            background-color: #fff;
            @media (max-width: map-get($grid,lg)){
                width: .5rem;
                height: .5rem;
            }
        }
    }
}

.form-upload{
    position: relative;
    display: flex;
    align-items: center;
    gap:0 1rem;
    input[type='file']{
        position: absolute;
        left: -9999em;
        opacity: .1;
        width: .1rem;
        height: .1rem;
        overflow: hidden;
    }
    @media (max-width: map-get($grid,lg)){
        .form-control{
            width: calc(100% - 8rem);
        }
    }
}