@import '../utils/var';

.footer{
    margin-top: 12rem;
    @media (max-width : map-get($grid, lg)) {
        margin-top: 8rem;
    }
    &--menu{
        background-color: #242e42;
        line-height: 1;
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 8rem;
            @media (max-width : map-get($grid, lg)) {
                height: auto;
                display: block;
                padding: 1.5rem 0;
            }
        }
    }
    &--end{
        background-color: #ecf0f2;
        padding: 5.5rem 0;
        @media (max-width : map-get($grid, lg)) {
            padding: 3rem 0;
        }
    }
}

.footer--info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width : map-get($grid, lg)) {
        display: block;
    }
}
.footer--address{
    p{    
      font-size: 1.7rem; 
    }
    div{
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 1.5rem;
        font-size: 1.7rem;
    }
    @media (max-width : map-get($grid, lg)) {
        p{
            font-size: 1.3rem;
        }
        div{
            font-size: 1.3rem;
            margin-top: 1rem;
        }
    }
}
.footer--logos{
    display: flex;
    gap: 0 5rem;
    @media (max-width : map-get($grid, lg)) {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        padding-top: 1.5rem;
    }
}
.copyright{
    color: #717171;
    font-size: 1.5rem;
    margin-top: 4rem;
    @media (max-width : map-get($grid, lg)) {
        margin-top: 2rem;
        font-size: 1.2rem;
        text-align: center;
    }
}