@import '../utils/var';

.brand{
    position: relative;
    z-index: 12;
    width: 21.6rem;
    height: 3.9rem;
    > a{
        display: block;
        width: 100%;
        height: 100%;
        background: url(#{$path}/logos/logo@216x39.png) no-repeat 50% 50%/ 100% auto;   
    }
    @media (max-width: map-get($grid,lg)) {
        width: 12rem;
        height: 2.9rem;
    }
}
