@import '../utils/var';


.footer--menu--ul{
    display: flex;
    gap: 0 .5rem;
    @media (max-width : map-get($grid, lg)) {
        justify-content: center;
        padding-bottom: 1.5rem;
    }
    li{
        a{
            color: #fff;
            font-size: 1.7rem;
            opacity: 0.5;
            &:has(b){
                opacity: 1;
            }
            @media (max-width : map-get($grid, lg)) {
                font-size: 1.2rem;
            }
        }
        &:not(:first-child){
            position: relative;
            padding-left: 2rem;       
            margin-left: 2rem;
            @media (max-width : map-get($grid, lg)) {
                margin-left: 1rem;
                padding-left: 1rem;
            }
            &::after{
                content:"";
                display: block;
                position: absolute;
                left: 0;
                top: .3rem;
                width: .1rem;
                height: 1.4rem;
                background-color: #fff;
                opacity: 0.5;
                @media (max-width : map-get($grid, lg)) {
                    height: 1rem;
                    top: .1rem;
                }
            }
        }
    }
}