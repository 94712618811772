@import '../../styles/utils/var';


.step-progress{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 .5rem;
    color: #717171;
    font-size: 1.7rem;
    padding: 8rem 0 2rem;
}
.step-current{
    color: var(--font-hover);
}

.step-info{
    text-align: center;
    padding-bottom: 4rem;
    h3{
        color: #000;
        font-size: 3.2rem;
    }
    p{
        color: #2d2d2d;
        font-size: 1.9rem;
        margin-top: 1rem;
    }
}