@import '../utils/var';

#nav{
    @media (max-width: map-get($grid,lg)) {
        display: none;
    }
}
.gnb{
    display: flex;
}
.gnb-li1{
    position: relative;
    padding: 0 3.5rem;
    transition: all .5s;
    .active &{
        padding: 0 7rem;
    }
    > a{
        font-size: 1.9rem;
        height: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover,
        &:focus{
            color: var(--font-hover);
        }
    }
    &.active{
        > a{
            color: var(--font-hover);
        }
    }
    &:first-child{
        .gnb-drop-menu{
            border-left: 1px solid #e5e5e5;
        }
    }
}


.gnb-drop-menu{
    border-right: 1px solid #e5e5e5;
    height: 18rem;
    left: 0;
    padding: 2rem 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    ul{
        li{
            a{
                color: #999;
                display: block;
                font-size: 1.4rem;
                font-weight: 500;
                padding: 1.05rem 0;
                text-align: center;
                &:hover,
                &:focus{
                    color: var(--font-hover);
                }
            }
        }
    }
}


.header.active{
    .header--wrap{
        overflow: hidden;
    }
    .gnb-drop-menu{
        opacity: 1;
        visibility: visible;
    }
}