@import '../utils/var';
body{
    &:has(.notfound){
        #contents{padding: 0 !important;} 
        .header,
        .footer{
            display: none;
        }        
    }    
}
.notfound{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.error-title{
    padding: 3rem 0;
    font-size: 3rem;
}
.error-text{
    color: #666;
    line-height: 1.6;
    font-size: 1.6rem;
}

.retry-button{
    margin-top: 4.5rem;
    .button{
        padding-left: 6rem;
        padding-right: 6rem;
        font-weight: 700;
    }
}


.cm-no-data{
    min-height:300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e0e0e0;
    background: #ecf0f2;
    border-radius: 6px;
    .cm-no-data-wrap{
        text-align: center;
        > div{
            text-align: center;margin-top: 20px;
            line-height: 1.25em;font-weight: 400;
            color: #757575;
        }
        .cs-icon-warning_gray {
            display: inline-block;
            vertical-align: middle;
            width: 53px;
            height: 46px;
            font-size: 0;
            background-image: url(#{$path}/404/icon_warning_gray.png);
            background-position: center;
            background-repeat: no-repeat;
            margin-top: -2px;
            background-size: 100% 100%;
        }
    }
}

