@import '../../styles/utils/_var';

.main-section4{
    padding: 12rem 0 10rem;
    background: url(#{$path}/main/ms4-bg.png) no-repeat 50% 50%;
    background-size: cover;
    .link-area{
        display: flex;
        justify-content: center;
        padding: 6rem 0 0;
        a{
            color: #fff;
            border: 1px solid #fff;
            border-radius: 4rem;
            font-size: 2.1rem;
            padding: 2rem 5.5rem;
            transition: all .2s;
            &:hover,
            &:focus{
                background-color: #fff;
                color: var(--font-hover);
            }
        }
    }
    @media (max-width: map-get($grid, lg)) {
        padding: 8rem 0 6rem;
        .link-area{
            padding: 4rem 0 0;
            a{
                font-size: 1.5rem;
                padding: 1rem 2.7rem;
            }
        }
    }
    @media (max-width: map-get($grid, md)) {
        padding: 7rem 0 4rem;
    }
    .main-hgroup b{color: #fff;}
}
.main-section4-links{
    display: grid;
    grid-template-columns: repeat(2, minmax(0,1fr));
    gap: 3rem;
    margin-top: 5rem;
    @media (max-width: map-get($grid, lg)) {
        gap: 1.5rem;
        margin-top: 3rem;
        grid-template-columns: repeat(1, minmax(0,1fr));
    }
    .item{
        background-color: #fff;
        border-radius: 1rem;
        padding: 7rem 6rem;
        display: flex;
        align-items: center;
        @media (max-width: map-get($grid, lg)) {
            padding: 4rem 3rem;
        }
        @media (max-width: map-get($grid, md)) {
            padding: 2rem 1.5rem;
            flex-direction: column;
            gap: 1.5rem 0;
        }
        @for $i from 1 through 4 {
            &:nth-child(#{$i}){
                .icon{background-image: url(#{$path}/main/ms4-icon-#{$i}.png);}
            }
        }
        .icon{
            background-repeat: no-repeat;
            background-color: #ecf0f2;
            background-position: 50% 50%;
            border: 1px solid #e5eaec;
            border-radius: 1.5rem;
            width: 10rem;
            aspect-ratio: 1;
            @media (max-width: map-get($grid, lg)) {
                width: 7rem;
            }
            @media (max-width: map-get($grid, md)) {
                margin: 0 auto;
                width: 6rem;
            }
        }
        .figure{
            padding-left: 4rem;
            line-height: 1;
            width: calc(100% - 10rem);
            @media (max-width: map-get($grid, md)) {
                width: 100%;
                padding-left: 0;
            }
        }
        .kr{
            display: flex;
            gap: 0 1rem;
            color: #000;
            font-size: 2.8rem;
            align-items: end;
            em{
                color: #c6c6c6;
                font-size: 1.6rem;
            }
            @media (max-width: map-get($grid, lg)) {
                font-size: 2rem;
                gap: 0 .5rem;
                em{
                    font-size: 1.4rem;
                }
            }
            @media (max-width: map-get($grid, md)) {
                font-size: 1.8rem;
                em{
                    font-size: 1.2rem;
                }
            }
        }
        .desc{
            color: #555555;
            line-height: 1.4;
            font-size: 1.7rem;
            margin-top: 1.5rem;
            @media (max-width: map-get($grid, lg)) {
                font-size: 1.3rem;
            }
            @media (max-width: map-get($grid, md)) {
                font-size: 1.1rem;
                margin-top: 1rem;
            }
        }
    }
}