@import '../../styles/utils/var';

.gall{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 3rem;
    @media (max-width: map-get($grid, lg)) {
        gap: 1.5rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media (max-width: map-get($grid, md)) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }   
    border-top: 1px solid #000;
    padding-top: 2rem;
}

.gall-item{
    border-radius:1.6rem;
    padding: 0.4rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    figure{
        margin: 0;
        padding: 0;
        a{
            display: contents;
        }
    }
    .thumbnail{
        overflow: hidden;
        position: relative;
        border-radius: 1.6rem 1.6rem 0 0;
        background-image: url(#{$path}/icon/no-img.png);
        background-position: center;
        background-repeat: no-repeat;
        background-color: #ecf0f2;
        .cate{
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 1.5rem;
            top: 1.5rem;
            border-radius: 2rem;
            height: 3.2rem;
            width: 8.5rem;
            font-size: 1.5rem;
            font-weight: 700;
            background-color: rgba(255,255,255,0.8);
            @media (max-width: map-get($grid, lg)) {
                height: 3rem;
                width: 6rem;
                font-size: 1.2rem;
            }
        }
        img{
            
            overflow: hidden;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%) scale(1);
            transition: all 5s;
            width: 100%;
            height: 100%;
            margin: 0.1rem;
            border-radius: 1.6rem 1.6rem 0 0;
        }
        a{
            
            display: block;
            overflow: hidden;
            position: relative;
            padding-bottom: 74.09%;
            &:hover,
            &:focus{
                img{
                    transform: translate(-50%,-50%) scale(1.05);
                }
            }
        }
    }
    .caption{
        position: relative;
        margin-top: 2rem;
        padding: 0 2rem 1.5rem 2rem ;
        line-height: 1;
        h3{
            display: flex;
            gap: 0.5rem;
            vertical-align: top;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #000;
            font-size: 1.5rem;
            width: calc(100% - 3.5rem);
            .new-ico{
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                background-image: url(#{$path}/icon/new-ico.png);
            }
        }
        .desc{
            display: flex;
            justify-content: space-between;
            color: #8e8e8e;
            font-size: 1.3rem;
            padding-bottom: 0.6rem;
            b{
                color: #f16621;
            }
            p{
                line-height: 1.3;
            }
        }
        .desc2{
            display: flex;
            justify-content: flex-end;
            color: #8e8e8e;
            font-size: 1.3rem;
            padding-top: 0.6rem;
            b{
                color: #f16621;
            }
            p{
                line-height: 1.3;
            }
        }
        .button-favorites{
            position: absolute;
            right: 0;
            top: 0;
        }
        @media (max-width: map-get($grid, lg)) {
            margin-top: 1.5rem;
            h3{
                font-size: 1.7rem;
            }
            .desc{
                font-size: 1.2rem;
            }
        }
    }
}

.search-input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    input.form-control {
        margin-right: 1rem;
    }
}