@import '../../styles/utils/_var';

.main-visual{
    background:#242e42 url(#{$path}/main/visual.png) no-repeat center center/cover;
    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 84rem;
        @media (max-width: map-get($grid, lg)) {
            height: calc(100vh - 6rem);
            min-height: 40rem;
        }
    }
    .group{
        text-align: center;
        line-height: 1;
        h3{
            color: #fff;
            font-size: 8rem;
            font-family: 'intelone-mono-font-family-regular';
            letter-spacing: -0.1rem;
            b{
                color: var(--font-hover);
            }
            @media (max-width: map-get($grid, lg)) {
                font-size: 6rem;
            }
        }
        h4{
            font-size: 5rem;
            padding: 3rem 0 4rem;
            font-family: 'intelone-mono-font-family-regular';
            color: #fff;
            @media (max-width: map-get($grid, lg)) {
                font-size: 4rem;
                padding: 2rem 0 1.5rem;
            }
            @media (max-width: map-get($grid, md)) {
                font-size: 2rem;
                line-height: 1.3;
            }
        }
        p{
            color: #fff;
            line-height: 1.6;
            opacity: .5;
            font-size: 2.4rem;
            @media (max-width: map-get($grid, lg)) {
                font-size: 1.4rem;
            }
        }
    }
    .icon--arrowdown-white{
        position: absolute;
        bottom: 4rem;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: map-get($grid, lg)) {
            bottom: 2rem;
            transform: scale(0.575) translateX(-50%);
        }
    }
}