@import '../../styles/utils/_var';

.main-section3{
    padding-bottom: 11rem;
    .main-hgroup{
        padding-bottom: 10rem;
    }
    @media (max-width: map-get($grid , lg)) {
        padding-bottom: 7rem;
        .main-hgroup{
            padding-bottom: 3rem;
        }
    }
}