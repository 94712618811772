@import '../utils/var';

.footer--links{
    position: relative;
    button{
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5rem;
        width: 25rem;
        height: 4rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        i{
            display: inline-block;
            position: relative;
            transition: all 0.5s;
            width: 1.2rem;
            height: .6rem;
            background: url(#{$path}/icon/arrow@12x6-black.png) no-repeat 0 0;
        }
        &[aria-expanded="true"]{
            border-radius: 1.5rem 1.5rem 0 0;
            i{
                transform: rotate(180deg);
            }
            +ul{
                display: block;
            }
        }
        @media (max-width : map-get($grid, lg)) {
            margin: 0 auto;
            max-width:30rem;
            height: 3.2rem;
            font-size: 1.3rem;
        }
    }
    ul{
        z-index: 10;
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        background-color: #fff;
        li{
            a{
                display: block;
                padding: 1.5rem;
                font-size: 1.5rem;
            }
        }
    }
}