@import "./utils/var";
:root {
    --font-name: "Pretendard";
    --font-size: 1.6rem;
    --font-weight: 400;
    --font-color: #000;
    --font-hover : #007fff;
    --font-current : #007fff;
    --color-default : #000;
    --color-hover : #007fff;
    --color-current : #007fff;
    --color-disabeld : #eee;
    --line-height: 24px;
    --grid-sm: 57.6rem;
    --grid-md: 76.8rem;
    --grid-lg: 100rem;
    --grid-xl: 144rem;
    --gap: 1.5rem;
}

*{
    box-sizing: border-box;
}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,button {
    margin: 0;
    padding: 0;
}

fieldset,img {
    border: 0 none
}

dl,ul,ol,menu,li {
    list-style: none
}

blockquote,q {
    quotes: none
}

blockquote:before,blockquote:after,q:before,q:after {
    content: "";
    content: none
}

input,select,textarea,button {
    vertical-align: middle
}

input::-ms-clear {
    display: none
}

button {
    border: 0 none;
    background-color: transparent;
    cursor: pointer
}
body,th,td,input,select,textarea,button {
    color: var(--font-color);
    font-family: var(--font-name);
}
a {
    color: #333;
    text-decoration: none;
    cursor: pointer
}
a:active,a:hover {
    text-decoration: none
}
a:active {
    //background-color: transparent
}

address,caption,cite,code,dfn,em,var {
    font-style: normal;
    font-weight: 400
}
html {
    font-size: 10px
}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,button{
    font-size: var(--font-size);
}
img{vertical-align: top;max-width: 100%;}


.sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
    white-space: nowrap;
    text-indent: -9999px;
    border-radius: 0;
}


.row{
    margin: 0 var(--gap);
}
.container{
    max-width: var(--grid-xl);
    margin: 0 auto;
    position: relative;
}

.mt-3{
    margin-top: 3rem;
}
.md-mt-3{
    @media (max-width: map-get($grid,lg)) {
        margin-top: 1.5rem;    
    }
}

.flex{
    display: flex;
}
.justify-between{
    justify-content: space-between;
}
.justify-center{
    justify-content: center;
}
.justify-end{
    justify-content: end;
}
.align-items{
    align-items: center;
}

.small-wraps{
    margin: 0 auto;
    background-color: #ecf0f2;
    border: 1px solid #cad4d9;
    border-radius: 1rem;
    padding: 3rem;
    max-width:51.4rem;
    @media (max-width : map-get($grid , lg)) {
        padding: 2rem;
        max-width: 100%;
    }
}

table{
    border-collapse: collapse;
	border-spacing: 0;
}

.tac{
    text-align: center !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}