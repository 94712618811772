@import "../../styles/utils/var";
.features-digital{
    p{
        text-align: center;
        font-size: 2.1rem;
        line-height: 1.5;
        &:first-child{
            margin-top: 4rem;
        }
        &:not(:first-child){
            margin: 4rem 0;
        }
        @media (max-width: map-get($grid, lg)) {
            font-size: 1.5rem;
            &:first-child{
                margin-top: 2rem;
            }
            &:not(:first-child){
                margin: 2rem 0;
            }   
        }
        @media (max-width: map-get($grid, sm)) {
            font-size: 1.3rem;
        }
    }
    .button--round{
        padding-left: 3rem;
        padding-right: 3rem;
    }
    dl{
        text-align: center;
        margin-bottom: 5rem;
        @media (max-width: map-get($grid, sm)) {
            margin-bottom: 3rem;
        }
        dt{
            font-size: 3.5rem;
            font-weight: bold;
            @media (max-width: map-get($grid, sm)) {
                font-size: 2rem;
            }
        }
        dd{
            font-size: 2.1rem;
            @media (max-width: map-get($grid, sm)) {
                font-size: 1.5rem;
            }
        }
    }
    .manager-serivce-flow{
        text-align: center;
        margin-bottom: 4rem;
        @media (max-width: map-get($grid, sm)) {
            margin-bottom: 2rem;
        }
        h3{
            font-size: 2.5rem;
            color:#2e7ac7;
            margin-bottom: 1.5rem;
            @media (max-width: map-get($grid, sm)) {
                font-size: 1.8rem;
                margin-bottom: 1rem;
            }
        }
    }
}
.link-view{
    margin-top: 4rem;
    display: flex;
    justify-content: center;
}

.image-list{
    display: grid;
    grid-template-columns: repeat(3, minmax(0,1fr));
    gap: 0 3rem;
    img{
        object-fit: cover;
        object-position: center center;
    }
    @media (max-width: map-get($grid, md)) {
        gap: 0 1rem;
    }
}

.features-video{
    .flex{
        .col-4{
            width: 46%;
        }
        .col-7{
            width: 64%;
        }
        @media (max-width: map-get($grid, md)) {
            display: block;
            .col-4,
            .col-7{
                width: 100%;               
            }
            &.nth-2{
                display: flex;
                flex-direction: column-reverse;
                
            }
        }
        dl{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding-left: 8rem;
            dt{
                font-size: 3.5rem;
                font-weight: bold;
            }
            dd{
                font-size: 2.1rem;
                line-height: 1.5;
            }
            @media (max-width: map-get($grid, md)) {
                padding-left: 0;
                margin-bottom: 3rem;
                dt{
                    font-size: 2.5rem;
                }
                dd{
                    font-size: 1.5rem;
                }
            }
        }
    }
    .button--round{
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

.service-txt-top{
    font-size: 2.1rem;
    margin-bottom: 3.7rem;
    line-height: 1.5;
    white-space: pre-wrap;
    text-align: center;
    @media (max-width: map-get($grid, md)) {
        font-size: 1.5rem;
        line-height: 1.3;
        .br-responsive{
            display: none;
        }
    }
}
.service-target{
    margin-bottom: 8rem;
    @media (max-width: map-get($grid, md)) {
        margin-bottom: 4rem;
    }
}

.service-card{
    overflow: hidden;
    background: #f0f0f0;
    border-radius: 0.6rem;
    padding: 3rem;
    @media (max-width: map-get($grid, md)) {
        padding: 1.5rem;
    }
    .service-card-ti{
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 3rem;
        @media (max-width: map-get($grid, md)) {
         font-size: 1rem;
         margin-bottom: 1.5rem;
        }
    }
    .service-card-cont{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        @media (max-width: map-get($grid, md)) {
            flex-direction: column;
        }
        img{
            max-width: 63rem;
        }
        p{
            margin: 1rem;
            @media (max-width: map-get($grid, md)) {
                font-size: 1.3rem;
            }
        }
    }
}

.service-dl{
    dt{
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
        @media (max-width: map-get($grid, md)) {
            font-size: 1.8rem;
        }
    }
    dd{
        font-size: 1.9rem;
        @media (max-width: map-get($grid, md)) {
            font-size: 1.4rem;
        }
    }
}
.service-ul{
    margin-top: 4rem;
    @media (max-width: map-get($grid, md)) {
        margin-top: 2rem;
    }
    dt{
        font-size: 1.7rem;
        font-weight: bold;
        color:#1d1d1d;
        margin-bottom: 2.4rem;
        @media (max-width: map-get($grid, md)) {
            font-size: 1.3rem;
            margin-bottom: 1.8rem;
        }
        span{
            color:#2e7ac7;
            display: inline-block;
            margin-right: 0.6rem;
        }
    }
    dd{
        ul{
            li{
                position: relative;
                color:#555555;
                padding-left: 1rem;
                font-size: 1.5rem;
                margin-bottom: 1.2rem;
                &:after{
                    position: absolute;
                    left: 0;
                    top: 0.7rem;
                    display: block;
                    content:'';
                    width: 0.4rem;
                    height: 0.5rem;
                    background: #2e7ac7;
                    border-radius:50%;
                    @media (max-width: map-get($grid, md)) {
                        top: 0.4rem;
                        width: 0.3rem;
                        height: 0.3rem;
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
                @media (max-width: map-get($grid, md)) {
                    font-size: 1.2rem;
                    margin-bottom: 0.8rem;
                }
            }
        }
    }
}

.center{
    text-align: center;
}
.mb15{
    margin-bottom: 1.5rem;
    @media (max-width: map-get($grid, md)) {
        margin-bottom: 1rem;
    }
}

.mb30{
    margin-bottom: 3rem;
    @media (max-width: map-get($grid, md)) {
        margin-bottom: 2rem;
    }
}

.mb40{
    margin-bottom: 4rem;
    @media (max-width: map-get($grid, md)) {
        margin-bottom: 3rem;
    }
}
.mb50{
    margin-bottom: 5rem;
    @media (max-width: map-get($grid, md)) {
        margin-bottom: 4rem;
    }
}

.mb80{
    margin-bottom: 8rem;
    @media (max-width: map-get($grid, md)) {
        margin-bottom: 5rem;
    }
}

