@import "../../styles/utils/var";

.product-grid{
    background: #eaeef2;
    border: 1px solid #c6c6c6;
    padding: 3.5rem 2.8rem;
    border-radius: 1.2rem;
    .col-header{
        display: inline-block;
        padding-bottom: 3px;
        color:#555555;
        border-bottom: 1px solid #2e7ac7;
        h4{
            color:#2e7ac7;
        }
    }
    .user-use-date{
        color:#555555;
    }
    .user-use-progress{
        .desc{
            color:#555555;
            b{
                color:#2e7ac7
            }
        }
    }
    
}
.product-li-wrap{
    display: flex;
    @media (max-width: map-get($grid, md)) {
        flex-direction: column;
        gap:2rem
    }
    ul{
        width: 20%;
        @media (max-width: map-get($grid, md)) {
            width: 100%;
            
        }
        &:first-child{
            border: 1px solid transparent;
            @media (max-width: map-get($grid, md)) {
                display: none;
            }
        }
        li{
            height: 7.5rem;
            @media (max-width: map-get($grid, md)) {
                height: 4.5rem;
            }
            &:first-child{
                height: 445px;
                padding: 2.4rem 1.3rem ;
                text-align: center;
                @media (max-width: map-get($grid, md)) {
                    height: auto;
                }
                h2{
                    position: relative;
                    font-size: 2.5rem;
                    margin-bottom: 15px;
                    padding-top: 7rem;
                    &:after{
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        display: block;
                        content:'';
                        width: 5rem;
                        height: 5rem;
                        background-repeat: no-repeat;
                    }
                }
                p{
                    font-size: 1.5rem;
                    margin-bottom: 20px;
                    min-height: 6rem;
                    @media (max-width: map-get($grid, md)) {
                        min-height: auto;
                    }
                }
                dl{
                    display: flex;
                    font-size: 1.7rem;
                    background: #ecf0f2;
                    justify-content: space-between;
                    height: 7rem;
                    align-items: center;
                    margin-bottom: 0.8rem;
                    padding: 1.5rem;
                    &+dl{
                        margin-bottom: 2.4rme;
                    }
                    @media (max-width: map-get($grid, md)) {
                        height: 5rem;
                    }
                }
            }
            &:nth-child(even) {
                background-color: #ecf0f2;
            }
            padding: 1.5rem 2.5rem;
            .pc-none{
                display: none;
                @media (max-width: map-get($grid, md)) {
                    display: block;
                }
            }
        }
    }
    .card-li{
        border: 1px solid #ecf0f2;
        border-radius: 1.2rem;
        transition: all 0.3s;
        &:hover{
            border: 1px solid #242e42;
        }
        li{
            &:nth-child(n+2){
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &:last-child{
                border-radius:0 0 1.2rem 1.2rem;
            }
            dl{
                @media (max-width: map-get($grid, md)) {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                }
                dt{
                    @media (max-width: map-get($grid, md)) {
                        font-size: 1.3rem;
                    }
                }
                dd{
                    text-align: center;
                    @media (max-width: map-get($grid, md)) {
                        font-size: 1.3rem;
                    }
                }
            }
            &.basic-li-fir{
                h2{
                    &:after{
                        background-image: url(#{$path}/mypage/ico-basic.png);
                    }
                }
            }
            &.standard-li-fir{
                h2{
                    &:after{
                        background-image: url(#{$path}/mypage/ico-standard.png);
                    }
                }
            }
            &.premium-li-fir{
                h2{
                    &:after{
                        background-image: url(#{$path}/mypage/ico-premium.png);
                    }
                }
            }
            &.custom-li-fir{
                h2{
                    &:after{
                        background-image: url(#{$path}/mypage/ico-custom.png);
                    }
                }
            }
        }
    }
}


.product-ti{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    &.border{
        padding-bottom: 1.5rem;
        border-bottom: 1px solid #333333;
    }
    h3{
        position: relative;
        padding-left: 2.3rem;
        &:after{
            position: absolute;
            left: 0;
            top: 0.4rem;
            display: block;
            content:'';
            width: 10px;
            height: 10px;
            background: #435c7f;
            border-radius: 50%;
        }
    }
}


.no-data-wrap{
    margin: 10rem 0;
    text-align: center;
    padding-top: 12rem;
    background-image: url(#{$path}/mypage/no-data.png);
    background-repeat: no-repeat;
    background-position: center top;
    @media (max-width: map-get($grid, md)) {
        background-size: 10%;
        padding-top: 6rem;
    }
    .big-ti{
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 2rem;
        @media (max-width: map-get($grid, md)) {
            font-size: 1.8rem;
            margin-bottom: 1rem;
        }
    }
    .small-ti{
        font-size: 1.6rem;
        color:#666666;
        margin-bottom: 6rem;
        span{
            color:#2e7ac7;
        }
        @media (max-width: map-get($grid, md)) {
            font-size: 1.2rem;
            margin-bottom: 3rem;
        }
    }
}