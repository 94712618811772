@import '../../styles/utils/var';


.register-form-section{
    border: 1px solid #bdbdbd;
    border-radius: 1rem;
    padding: 3rem;
    +.register-form-section{
        margin-top: 3rem;
    }
    header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3rem;
        h3{
            color: #000;
            font-weight: 400;
            font-size: 2.1rem;
        }
        .required{
            color: #2e7ac7;
            font-size: 1.9rem;
            i{
                vertical-align: middle;
                margin-right: 1rem;
            }
        }
    }
    @media (max-width: map-get($grid, lg)) {
        padding: 2rem;
        +.register-form-section{
            margin-top: 2rem;
        }
        header{
            margin-bottom: 2rem;
            h3{
                font-size: 1.6rem;
            }
            .required{
                font-size: 1.4rem;
                i{
                    margin-right: .5rem;
                }
            }
        }
    }
    @media (max-width: map-get($grid, sm)) {
        header{
            flex-direction: column;
            gap: 1rem 0;
        }
    }
}

.regi-input{
    position: relative;
    max-width:59rem;
    padding-right: 12rem;
    .button{
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
    }
    @media (max-width: map-get($grid, lg)) {
        padding-right: 8rem;
        &:not(:has(.button)){
            max-width: 100%;
            padding-right: 0;
        }
    }
}

