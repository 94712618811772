@import '../utils/var';

.page-titles{
    text-align: center;
    padding-bottom: 4rem;
    @media (max-width: map-get($grid , lg)) {
        padding-bottom: 2.5rem;
    }
}
.page-title{
    color: #000000;
    font-weight: 400;
    font-size: 3.2rem;
    @media (max-width: map-get($grid , lg)) {
        font-size: 2rem;
    }
}
.page-desc{
    font-size: 1.9rem;
    margin-top: 1rem;
    @media (max-width: map-get($grid , lg)) {
        font-size: 1.3rem;
        margin-top: .7rem;
    }
}