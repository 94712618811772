@import "../utils/var";
.board{
    &:has(.pagination){
        .pagination{
            margin-top: 4rem;
        }
    }
}

.pagination{
    display: flex;   
    align-content: center;
    justify-content: center;
    gap: 0 3.5rem;
    @media (max-width:map-get($grid , lg)) {
        gap:0 1.5rem;
    }
    .prev,
    .next{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 1rem;
        color: #555555;
        font-size: 1.5rem;
        i{margin-top: -.2rem;}
        @media (max-width:map-get($grid , lg)) {
            font-size: 1.2rem;
            i{
                transition: scale(0.9);
            }
        }
    }
    span{
        display: flex;       
        align-items: center;
        justify-content: center;
        gap: 0 1rem;
    }
    .paging{
        width: 4rem;height: 4rem;
        border-radius: 0.5rem;
        font-size: 1.7rem;
        @media (max-width:map-get($grid , lg)) {
            font-size: 1.2rem;
            width: 3.2rem;
            height: 3.2rem;
        }
        &:hover,
        &:focus{
            background-color: #f2f6fa;
        }
        &[aria-current='true']{
            background-color: #242e42;
            font-weight: 700;
            color: #fff;
        }
    }
}