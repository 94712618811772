@import '../../styles/utils/_var';

@font-face {
    font-family: 'intelone-mono-font-family-regular';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2307-1@1.1/intelone-mono-font-family-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

.md-block{
    display: none;
}
@media (max-width: map-get($grid , md)) {
    .md-block{display: block;}
}

.main-hgroup{
    color: #000;
    font-size: 4rem;
    line-height: 1.4;
    text-align: center;
    @media(max-width : map-get($grid, lg)){
        font-size: 2rem;
    }
    b{
        color: var(--font-hover);
    }
}