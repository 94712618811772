@import "../../styles/utils/var";

.product-dl{
    text-align: center;
    dt{
        font-size: 4rem;
        margin-bottom: 2rem;
        @media (max-width: map-get($grid, md)) {
            font-size: 2.5rem;
            margin-bottom: 1rem;
        }
        span{
            color:#2e7ac7;
            font-weight: bold;
        }
    }
    dd{
        font-size: 2.1rem;
        color:#1d1d1d;
        @media (max-width: map-get($grid, md)) {
            font-size: 1.5rem;
        }
    }
}