@import "../utils/var";

.board-controls{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 0 1.5rem;
    &.end{
        justify-content: end;
    }
    @media (max-width: map-get($grid, lg)) {
        padding: 1.5rem 0 1rem;
    }
}
.board-display{
    color: #000;
    display: inline-flex;
    position: relative;
    align-items: center;
    font-size: 1.8rem;
    padding-left: 2.2rem;
    line-height: 1;
    //gap: 0 1rem;
    @media (max-width: map-get($grid, lg)) {
        padding-left: 1.5rem;
        font-size: 1.3rem;
    }
    @media (max-width: map-get($grid, md)) {
        span{display: none;}
    }
    &::after{
        content:"";
        display: block;
        width: 1rem;
        height: 1rem;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        background-color: #435c7f;
        @media (max-width: map-get($grid, lg)) {
            width: .6rem;
            height: .6rem;
        }
    }
    em{
        font-weight: 700;
        display: inline-block;
        margin-left: .5rem;
        color: #2e7ac7;
    }
}
.board-sort{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 0 .8rem;
    .form-select{
        width: 15rem;
    }
    @media (max-width: map-get($grid, lg)) {
        width: 50vw;
        gap: 0 .5rem;
        .form-select{
            //width: 50%;
            width: auto;
        }
    }
}
